export const environment = {
  production: false,
  server: 'https://api.dev.bookkeepingconnect.pwc.com/graphql',
  healthCheck: 'https://api.dev.bookkeepingconnect.pwc.com/health-check',
  subscription: 'wss://api.dev.bookkeepingconnect.pwc.com/graphql',
  iam: {
    url: 'https://login.microsoftonline.com/',
    tenant: 'b9ad39bb-d4e9-4878-ae67-b3a5f56a5906',
    authenticationPortal: '/oauth2/v2.0/authorize',
    logoutPortal: '/oauth2/v2.0/logout?post_logout_redirect_uri=',
    redirectUri: 'https://portal.dev.bookkeepingconnect.pwc.com',
    clientID: '3bb32dc8-ee4e-4f60-b46a-0828e3174663',
    responseType: 'code',
    logoutDuration: '60',
    scope:
      'openid%20offline_access%20profile%203bb32dc8-ee4e-4f60-b46a-0828e3174663/.default',
  },
  useMinus: false,
  name: 'dev',
  hideToolTips: false,
  cacheTime: 0,
  expiresIn: 13 * 60 * 1000,
  displaySNowWidget: true,
  pendoApi: '2a6fc0f4-6ed0-435c-78c0-d88218815803',
  internalDashboardUrl:
    'https://internal-dashboard.stage.insightsofficer.pwc.com/',
  bkcApi: 'AIzaSyAK1RihsmCSwZ-n4dH4C4XC5qX121clM4s',
  fastLinkURL: 'https://aggregationstage.pwc.com/authenticate/pwcagg/fastlink',
  termsOfUseUrl: 'https://productcentral.products.pwc.com/legal/Terms-of-Use',
  keeperLoginUrl: 'https://dev.keeper.app/login',
  quickBooksLoginUrl: 'https://accounts.intuit.com/app/sign-in',
  sageLoginUrl: 'https://www.intacct.com/ia/acct/login.phtml',
};
