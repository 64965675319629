import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { environment } from '@env';
import { User } from '@models';
import { Store } from '@ngxs/store';
import { Apollo } from 'apollo-angular';
import { plainToClass } from 'class-transformer';
import { isEqual } from 'lodash';
import { NgxPendoService } from 'ngx-pendo';
import { NgxPermissionsService } from 'ngx-permissions';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest,
  fromEvent,
  merge,
  of,
  timer,
} from 'rxjs';
import {
  map,
  startWith,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  getUser,
  grantAccessToken,
  launchKeeper,
  refreshAccessToken,
  renewUserSession,
  userLogin,
  userLogout,
} from '../gql';
import { FeatureManagementService } from './feature-management.service';
import { GqlService } from './gql.service';

export const REDIRECT_URL = `${environment.iam.redirectUri}/login`;

export const LOGIN_URL =
  `${environment.iam.url}${environment.iam.tenant}${environment.iam.authenticationPortal}` +
  `?response_type=${environment.iam.responseType}` +
  `&client_id=${environment.iam.clientID}&scope=${environment.iam.scope}&redirect_uri=${environment.iam.redirectUri}/login`;

export const LOGOUT_URL = `${environment.iam.url}${environment.iam.tenant}${
  environment.iam.logoutPortal
}${encodeURIComponent(environment.iam.redirectUri)}%2Fwelcome`;

export const TIMEOUT_URL = `${environment.iam.url}${environment.iam.tenant}${
  environment.iam.logoutPortal
}${encodeURIComponent(environment.iam.redirectUri)}%2Ftimeout`;

export const ACCESS_DENIED_URL = `${environment.iam.url}${
  environment.iam.tenant
}${environment.iam.logoutPortal}${encodeURIComponent(
  environment.iam.redirectUri,
)}%2Fsign%2Faccess-denied`;

export const AUTH_TOKEN_KEY = 'auth_token';

export const EXPIRED_KEY = 'expired';

export const REDIRECT_URI_KEY = 'redirect_uri';

export const TIMEOUT = 90 * 1000;

export interface CodeResponseParams {
  code: string;
}

declare var pcAnalyPerfBtnChecked;

interface AuthToken {
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
  idToken: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly store: Store,
    private readonly apollo: Apollo,
    private readonly gqlService: GqlService,
    private readonly permissionsService: NgxPermissionsService,
    private readonly pendoService: NgxPendoService,
    private readonly featureManagementService: FeatureManagementService,
  ) {}

  // Store user after initialized
  public user$: BehaviorSubject<User> = new BehaviorSubject(null);
  private user: User = null;
  checkedPermission$: Subject<boolean> = new Subject();
  checkedSession$: Subject<void> = new Subject();
  deactivation$: Subject<User> = new BehaviorSubject(null);

  private timer: Subscription;

  static setExpiresIn(expiresIn: any): void {
    localStorage.setItem(EXPIRED_KEY, JSON.stringify(expiresIn));
  }

  public static getSession() {
    return JSON.parse(localStorage.getItem(AUTH_TOKEN_KEY));
  }

  isLogin(): boolean {
    return this.user$.getValue() !== null;
  }

  login(isHostedBy: string = null): void {
    this.apollo.client.resetStore();
    localStorage.clear();
    if (this.route.snapshot.queryParams.redirectUri) {
      localStorage.setItem(
        REDIRECT_URI_KEY,
        this.route.snapshot.queryParams.redirectUri,
      );
    }
    if (isHostedBy) {
      localStorage.setItem('HOSTED_BY', `${isHostedBy}`);
    }
    window.location.replace(LOGIN_URL);
  }

  async logout(): Promise<void> {
    this.gqlService.mutate(userLogout, {}).subscribe((res) => {
      const { status, authUser } = res;

      localStorage.clear();
      const logoutUrl =
        status === 'SUCCESS' && authUser?.logoutHint
          ? `${LOGOUT_URL}&logout_hint=${authUser?.logoutHint}`
          : LOGOUT_URL;
      window.location.replace(logoutUrl);
    });
  }

  async launchKeeper(): Promise<void> {
    this.gqlService.mutate(launchKeeper, {}).subscribe((res) => {
      const { status, ott } = res;
      const keeperSSOUrl =
        status === 'SUCCESS'
          ? `${environment.keeperLoginUrl}?ott=${ott}`
          : environment.keeperLoginUrl;
      window.open(keeperSSOUrl, '_blank');
    });
  }

  timeout(): void {
    this.gqlService.mutate(userLogout, {}).subscribe((res) => {
      const { status, authUser } = res;

      localStorage.clear();
      const logoutUrl =
        status === 'SUCCESS' && authUser?.logoutHint
          ? `${TIMEOUT_URL}&logout_hint=${authUser?.logoutHint}`
          : TIMEOUT_URL;
      window.location.replace(logoutUrl);
    });
  }

  accessDenied(): void {
    window.location.replace(ACCESS_DENIED_URL);
  }

  checkSession(redirectUri?: string): number {
    const expiresIn = JSON.parse(localStorage.getItem(EXPIRED_KEY));
    if (!expiresIn) {
      const extras: NavigationExtras = {};
      if (redirectUri) {
        extras.queryParams = {
          redirectUri: encodeURIComponent(redirectUri),
        };
      }
      this.router.navigate(['welcome'], extras);
      return 0;
    }
    const diff = new Date(expiresIn).getTime() - new Date().getTime();
    if (diff <= 0) {
      this.timeout();
      return 0;
    }

    return diff;
  }

  dispatch(redirectUri?: string): void {
    if (!this.checkSession(redirectUri)) {
      return;
    }
    if (this.user) {
      setTimeout(() => {
        this.checkedPermission$.next(true);
      });
      this.user$.next(plainToClass(User, this.user));
      return;
    }
    const sub = this.gqlService
      .query(getUser, { tenantKey: null })
      .pipe(
        take(1),
        tap((user) => {
          if (user) {
            // "Analytical/Performance Cookies" is toggle on to set Pendo;
            if (pcAnalyPerfBtnChecked) {
              this.setPendo(user);
            }
            this.setPermissions(user);
            this.setTimer();
            if (!isEqual(this.user, user)) {
              this.user = user;
              this.user$.next(plainToClass(User, user));
            }
          } else {
            this.checkedPermission$.next(false);
            this.accessDenied();
            this.user$.next(null);
          }
        }),
      )
      .subscribe(() => sub.unsubscribe());
  }

  dispatchNewUser(tenantKey = null): Observable<User> {
    // this.gqlService.query(getUser, { tenantKey }).subscribe((user) => {
    //   this.user$.next(plainToClass(User, user));
    // });
    // return this.user$;
    return this.gqlService.query(getUser, { tenantKey }).pipe(
      tap((user) => this.user$.next(plainToClass(User, user))),
      map((user) => plainToClass(User, user)),
    );
  }

  setTimer() {
    if (this.timer) {
      this.timer.unsubscribe();
      this.timer = null;
    }

    const diff = this.checkSession();
    if (!diff) {
      return;
    }

    this.timer = timer(diff - TIMEOUT)
      .pipe(
        withLatestFrom(
          merge(
            fromEvent(window, 'scroll'),
            fromEvent(window, 'resize'),
            fromEvent(window, 'mousemove'),
            fromEvent(window, 'touchstart'),
            fromEvent(window, 'keydown'),
            fromEvent(window, 'mousedown'),
          ).pipe(startWith(null as Event), take(2)),
        ),
        switchMap(([_, event]) => {
          return combineLatest([
            of(event),
            // this.store.select((state) => state.user.user),
            this.user$,
          ]);
        }),
        tap(([event, user]) => {
          user = plainToClass(User, user);
          if (
            event ||
            window !== window.parent ||
            localStorage.getItem('HOSTED_BY') ||
            user?.isBookKeeperUser
          ) {
            this.refresh();
          } else {
            this.checkedSession$.next();
          }
        }),
      )
      .subscribe();
  }

  async refresh(): Promise<void> {
    const expired = JSON.parse(localStorage.getItem(EXPIRED_KEY));
    if (!expired) {
      this.router.navigate(['welcome'], {});
    }
    try {
      const expires = await this.renewUserSession();
      AuthService.setExpiresIn(expires);
      this.setTimer();
    } catch (error) {
      this.router.navigate(['welcome'], {});
    }
  }

  async authUserAfterLogin(path: string, redirect = true) {
    const authResponse = this.parseUrlString(path);
    const redirectUri = decodeURIComponent(
      localStorage.getItem(REDIRECT_URI_KEY) || '/',
    );
    if (!authResponse.code && this.hasTokenStorage()) {
      if (redirect) {
        this.router.navigateByUrl(redirectUri);
      }
      return;
    }
    if (!authResponse.code && !this.hasTokenStorage()) {
      return false;
    }
    // exchange code for token
    const token: any = await this.exchangeCodeForTokens(authResponse.code);
    if (!token || !token.accessToken || !token.refreshToken) {
      return false;
    }
    if (
      `${environment.iam.redirectUri}`.includes('localhost') ||
      `${environment.iam.redirectUri}`.includes('dev')
    ) {
      localStorage.setItem('token', token.idToken);
    }
    const { expires } = await this.gqlService
      .mutate(userLogin, { input: { idToken: token.idToken } })
      .toPromise();
    if (expires) {
      AuthService.setExpiresIn(expires);
    }
    if (redirect) {
      this.router.navigateByUrl(redirectUri);
    }
  }

  private parseUrlString(path: string): any {
    const queryString = path.replace(/^.*(#|\?)/, '');
    return queryString.split('&').reduce((acc, el) => {
      const parts = el.split('=');
      if (parts[0].indexOf('/') === 0) {
        parts[0] = parts[0].replace('/', '');
      }
      acc[parts[0]] = parts[1];
      return acc;
    }, {});
  }

  private setPendo(user: User) {
    this.pendoService.initialize(
      {
        id: `${user.userKey}-${environment.name}`,
        role: user.userType.nme,
        name: `${user.firstNme} ${user.lastNme}`,
        Environment: environment.name,
        Staff:
          (user.employee &&
            user.employee.employeeType &&
            user.employee.employeeType.nme) ||
          'N/A',
        'User Name': `${user.firstNme} ${user.lastNme}`,
        'User Role': user.userType.nme,
      },
      {
        id: user.tenant && `${user.tenant.tenantKey}-${environment.name}`,
        name: user.tenant && user.tenant.nme,
        'Customer Name': user.tenant && user.tenant.nme,
        Environment: environment.name,
      },
    );
  }

  setPermissions(user: User) {
    const permissions = [...user.permissions.map((p) => p.nme)];
    this.featureManagementService.oriPermission = permissions;
    user = plainToClass(User, user);
    if (user.isBookKeeperUser) {
      this.permissionsService.loadPermissions(permissions);
      this.checkedPermission$.next(true);
    } else {
      this.featureManagementService
        .getTenantDisPermission(user.tenant.tenantKey)
        .subscribe(({ disablePermissions }) => {
          const currentPermission = permissions.filter(
            (item) => !disablePermissions.includes(item),
          );
          this.permissionsService.loadPermissions(currentPermission);
          this.checkedPermission$.next(true);
        });
    }
  }

  /**
   * Exchange authorization code to access token and refhresh token
   */
  exchangeCodeForTokens = async (authCode: string): Promise<AuthToken> => {
    try {
      const variables = {
        input: {
          authCode,
          redirectUri: REDIRECT_URL,
        },
      };
      return await this.gqlService
        .mutate(grantAccessToken, variables)
        .pipe(
          map(({ accessToken, refreshToken, expiresIn, idToken }) => ({
            accessToken,
            refreshToken,
            expiresIn,
            idToken,
          })),
        )
        .toPromise();
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  /**
   * Refresh session
   */
  async renewUserSession() {
    try {
      return await this.gqlService
        .mutate(renewUserSession, {})
        .pipe(map(({ expires }) => expires))
        .toPromise();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  /**
   * Refresh token
   */
  renewToken = async (
    refreshToken: string,
  ): Promise<{ accessToken: string; expiresIn: number; idToken: string }> => {
    try {
      const variables = {
        input: {
          refreshToken,
          redirectUri: REDIRECT_URL,
        },
      };
      return await this.gqlService
        .mutate(refreshAccessToken, variables)
        .pipe(
          map(({ accessToken, expiresIn, idToken }) => ({
            accessToken,
            expiresIn,
            idToken,
          })),
        )
        .toPromise();
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  public hasBeenDeactivated(userInfo: User) {
    this.deactivation$.next(userInfo);
  }

  public redirectUrl(url: string): void {
    this.router.navigateByUrl(url);
  }

  private hasTokenStorage(): boolean {
    const token = JSON.parse(localStorage.getItem(EXPIRED_KEY));
    if (!token || !token.accessToken || !token.refreshToken) {
      return false;
    }
    return true;
  }
}
