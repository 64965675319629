import { Injector, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
// Apollo
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import {
  ApolloClientOptions,
  ApolloLink,
  DefaultOptions,
  InMemoryCache,
  split,
} from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { AuthService, states } from '@core';
import { environment } from '@env';
import { onError } from '@apollo/client/link/error';
import { OperationDefinitionNode } from 'graphql';
import extractFiles from 'extract-files/extractFiles.mjs';
import isExtractableFile from 'extract-files/isExtractableFile.mjs';
import { NgxsModule } from '@ngxs/store';

const createApollo = (
  httpLink: HttpLink,
  injector: Injector,
): ApolloClientOptions<any> => {
  // const http = httpLink.create({ uri: '/graphql' });
  const http = httpLink.create({
    uri: environment.server,
    withCredentials: true,
    extractFiles: (body) => extractFiles(body, isExtractableFile),
  });
  const setter = () => {
    const headers = {
      'x-api-key': `${environment.bkcApi}`,
    };
    if (
      `${environment.iam.redirectUri}`.includes('localhost') ||
      `${environment.iam.redirectUri}`.includes('dev')
    ) {
      headers['token'] = localStorage.getItem('token') || '';
    }
    return headers;
  };
  // const auth = setContext(() => ({ headers: setter() }));
  const auth = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: setter(),
    });
    return forward(operation);
  });
  const websocket = new WebSocketLink({
    uri: environment.subscription,
    options: {
      connectionParams: setter,
      timeout: 60000,
      reconnect: true,
      reconnectionAttempts: 10,
      lazy: true,
      inactivityTimeout: 60000,
    },
  });
  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      const authService = injector.get(AuthService);
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          if (err.message.includes('401')) {
            console.error(err.message);
          }
          if (err.message.includes('USER_DEACTIVATED')) {
            const { user } = JSON.parse(err.message);
            authService.hasBeenDeactivated(user);
          }
        }
        return forward(operation);
      }
      if (networkError) {
        console.log(`[Network error]: ${JSON.stringify(networkError)}`);
        let isCustomizedErr = false;
        (networkError as any)?.error?.errors?.forEach((x) => {
          if (x?.message?.includes('USER_UNAUTHENTICATED')) {
            authService.logout();
          } else if (x?.message?.includes('USER_SESSION_EXPIRED')) {
            isCustomizedErr = true;
            authService.redirectUrl('/timeout');
          }
        });
        if (
          !isCustomizedErr &&
          (networkError as any)?.name === 'HttpErrorResponse' &&
          /Http failure response for.*?/.test((networkError as any)?.message)
        ) {
          authService.redirectUrl('/server-error');
        }
        return forward(operation);
        // if you would also like to retry automatically on
        // network errors, we recommend that you use
        // apollo-link-retry
      }
    },
  );
  const link = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(
        query,
      ) as OperationDefinitionNode;
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    websocket,
    auth.concat(errorLink).concat(http),
  );
  const cache = new InMemoryCache();
  const defaultOptions: DefaultOptions = {
    // watchQuery: {
    //   fetchPolicy: 'no-cache',
    //   errorPolicy: 'ignore',
    // },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  };
  return { link, cache, defaultOptions };
};

@NgModule({
  imports: [
    NgxsModule.forRoot(states, { developmentMode: !environment.production }),
  ],
  exports: [ApolloModule, HttpClientModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, Injector],
    },
  ],
})
export class GraphQLModule {}
