import {
  AsyncPipe,
  NgClass,
  NgIf,
  NgStyle,
  ViewportScroller,
} from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import {
  AuthService,
  EmitService,
  LayoutService,
  MediaQueryServiceService,
} from '@core';
import { LayoutConfig, User } from '@models';
import { Observable, Subscription } from 'rxjs';
import { BackComponent } from '@shared/component/back/back.component';
import { CategoryComponent } from '@shared/component/category/category.component';
import { EncapsulatedBannerComponent } from '@shared/component/encapsulated-banner/encapsulated-banner.component';
import { FooterComponent } from '@shared/component/footer/footer.component';
import { BkNavbarComponent } from '@shared/component/bk-navbar/bk-navbar.component';
import { BkSidenavComponent } from '@shared/component/bk-sidenav/bk-sidenav.component';
import { SpinnerComponent } from '@shared/component/spinner/spinner.component';
import { TopGuideBannerComponent } from '@shared/component/top-guide-banner/top-guide-banner.component';
import { plainToClass } from 'class-transformer';
import { PanelSidenavComponent } from '../panel-sidenav/panel-sidenav.component';
import { NotificationsComponent } from '../notifications/notifications.component';
import { FeedbackComponent } from '../feedback/feedback.component';
import { FeedbackDetailComponent } from '../feedback-detail/feedback-detail.component';

@Component({
  selector: 'app-bk-main-layout',
  templateUrl: './bk-main-layout.component.html',
  styleUrls: ['./bk-main-layout.component.scss'],
  standalone: true,
  imports: [
    TopGuideBannerComponent,
    BkNavbarComponent,
    BkSidenavComponent,
    EncapsulatedBannerComponent,
    NgIf,
    BackComponent,
    RouterOutlet,
    FooterComponent,
    PanelSidenavComponent,
    NotificationsComponent,
    FeedbackComponent,
    SpinnerComponent,
    AsyncPipe,
    BkMainLayoutComponent,
    NgStyle,
    NgClass,
    CategoryComponent,
    FeedbackDetailComponent,
  ],
})
export class BkMainLayoutComponent implements OnInit, OnDestroy {
  layoutConfig: LayoutConfig;
  userContributor: boolean;

  user$: Observable<User> = this.authService.user$;
  userInfo: User;

  private readonly subs: Subscription[] = [];

  constructor(
    public layoutService: LayoutService,
    public mq: MediaQueryServiceService,
    public emitService: EmitService,
    private route: ActivatedRoute,
    private viewportScroller: ViewportScroller,
    private cdr: ChangeDetectorRef,
    public readonly authService: AuthService,
  ) {
    this.layoutConfig = layoutService.layoutConfig;
  }

  ngOnInit() {
    this.subs.push(
      this.user$.subscribe((user) => {
        this.userInfo = plainToClass(User, user);
        this.userContributor =
          user?.userType?.nme === 'CONTRIBUTOR' ? true : false;
      }),
      this.route.fragment.subscribe((fragment) => {
        if (fragment) {
          setTimeout(() => {
            const element = document.getElementById(fragment);
            if (element) {
              this.viewportScroller.scrollToAnchor(fragment);
            }
          }, 500);
        }
      }),
      this.emitService.loadingAPICountChanged$.subscribe(() => {
        this.cdr.detectChanges();
      }),
    );
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
