import { SortingType } from '@core/types';
import { User } from '@models';
import gql from 'graphql-tag';

export const saveFeedback = gql`
  mutation saveFeedback($input: FeedbackInput!) {
    saveFeedback(input: $input) {
      status
      message
    }
  }
`;

export const listFeedbackOwners = gql`
  query listFeedbackOwners {
    listFeedbackOwners {
      status
      message
      data {
        userKey
        email
        firstNme
        lastNme
      }
    }
  }
`;

export const listFeedback = gql`
  query listFeedback($input: ListFeedbackInput!) {
    listFeedback(input: $input) {
      status
      message
      count
      data {
        feedbackKey
        content
        createdAt
        feedbackType {
          displayName
          feedbackTypeKey
          name
        }
        user {
          userKey
          email
          firstNme
          lastNme
        }
        feedbackAttachments {
          feedbackAttachmentKey
          fileName
          storageFileName
          attachment {
            name
          }
        }
      }
    }
  }
`;

export const getFeedbackAttachment = gql`
  query getFeedbackAttachment($feedbackAttachmentKey: Int!) {
    getFeedbackAttachment(feedbackAttachmentKey: $feedbackAttachmentKey) {
      status
      message
      data {
        feedbackKey
        fileName
        storageFileName
        attachment {
          name
          base64Content
        }
      }
    }
  }
`;

export interface FeedbackDetailPanelInfo {
  isOpened: boolean;
  feedbackInfo: FeedbackInfo;
}

export interface FeedbackType {
  displayName: string;
  feedbackTypeKey: number;
  name: string;
}

export interface FeedbackInfo {
  user: User;
  feedbackType: FeedbackType;
  content: string;
  createdAt: string;
  feedbackAttachments: [FeedbackAttachment];
}

export interface FeedbackAttachment {
  feedbackKey: number;
  storageFileName: string;
  fileName: string;
  attachment: string;
}

export interface FeedbackInput {
  feedbackType: FEEDBACK_TYPE;
  content: string;
  attachments: [File];
}

export enum FEEDBACK_TYPE {
  COMPLIMENT = 'COMPLIMENT',
  SUGGESTION = 'SUGGESTION',
  PROBLEM = 'PROBLEM',
}

export enum FeedbackSortingType {
  TIMESTAMP = 'TIMESTAMP',
  USER_NAME = 'USER_NAME',
}

export interface ListFeedbackInput {
  searchText?: string;
  feedbackKey?: number;
  feedbackType?: FEEDBACK_TYPE;
  userKey?: number;
  startDate?: Date;
  endDate?: Date;
  orderBy?: FeedbackSortingType;
  sortingType?: SortingType;
  limit?: number;
  offset?: number;
}

export const FeedbackTypesList = [
  {
    label: 'Give a compliment',
    value: FEEDBACK_TYPE.COMPLIMENT,
    feedbackTypesKey: 1,
    name: FEEDBACK_TYPE.COMPLIMENT,
    displayName: 'Give a compliment',
  },
  {
    label: 'Make a suggestion',
    value: FEEDBACK_TYPE.SUGGESTION,
    feedbackTypesKey: 2,
    name: FEEDBACK_TYPE.SUGGESTION,
    displayName: 'Make a suggestion',
  },
  {
    label: 'Report a problem',
    value: FEEDBACK_TYPE.PROBLEM,
    feedbackTypesKey: 3,
    name: FEEDBACK_TYPE.PROBLEM,
    displayName: 'Report a problem',
  },
];
