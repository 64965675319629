import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DrawerModule } from '@appkit4/angular-components/drawer';
import { LoadingModule } from '@appkit4/angular-components/loading';
import { EmitService, FeedbackService, UtilService } from '@core';
import { FeedbackInfo } from '@core/gql/feedback.gql';
import { ClickOutsideDirective } from '@shared/directive/click-outside/click-outside.directive';
import { Subscription } from 'rxjs';
import { DecodeStringPipe } from '@shared/pipe/decode-string/decode-string.pipe';

@Component({
  selector: 'app-feedback-detail',
  templateUrl: './feedback-detail.component.html',
  styleUrls: ['./feedback-detail.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    DrawerModule,
    LoadingModule,
    ClickOutsideDirective,
    DecodeStringPipe,
  ],
})
export class FeedbackDetailComponent implements OnInit {
  list = [
    { name: 'Give a compliment', key: 'COMPLIMENT' },
    { name: 'Make a suggestion', key: 'SUGGESTION' },
    { name: 'Report a problem', key: 'PROBLEM' },
  ];

  clickOutsideEnabled = false;
  isOpened: boolean;
  feedbackInfo: FeedbackInfo;
  private readonly subs: Subscription[] = [];

  constructor(
    public readonly emitService: EmitService,
    private readonly feedbackService: FeedbackService,
    private readonly utilService: UtilService,
  ) {}

  ngOnInit(): void {
    this.subs.push(
      this.emitService.showFeedbackDetailPanel$.subscribe(
        ({ isOpened, feedbackInfo }) => {
          this.isOpened = isOpened;
          if (isOpened) {
            this.feedbackInfo = feedbackInfo;
            setTimeout(() => {
              this.clickOutsideEnabled = true;
            }, 1000);
          } else {
            this.feedbackInfo = null;
            this.clickOutsideEnabled = false;
          }
        },
      ),
    );
  }

  closeFeedbackDetailPanel() {
    this.emitService.showFeedbackDetailPanel$.next({
      isOpened: false,
      feedbackInfo: null,
    });
  }

  onClickedOutside(e: any) {
    if (
      !e.srcElement?.classList?.contains('panel-target') &&
      !e.srcElement?.classList?.contains('icon-circle-delete-outline') &&
      !e.srcElement?.classList?.contains('file-list-delete-all')
    ) {
      this.emitService.showFeedbackDetailPanel$.next({
        isOpened: false,
        feedbackInfo: null,
      });
    }
  }

  downloadAttachment(fileAttachment: any) {
    this.emitService.startLoading();
    this.feedbackService
      .getFeedbackAttachment(fileAttachment.feedbackAttachmentKey)
      .subscribe((res) => {
        this.emitService.endLoading();
        if (res.status === 'SUCCESS') {
          const { fileName, attachment } = res.data;
          this.utilService.downloadAttachmentFile(
            attachment.base64Content,
            fileName,
          );
        } else {
          this.utilService.addBannerMessage(res.message);
        }
      });
  }
}
